import { Button } from "antd"
import { Link } from "gatsby"
import React from "react"
import CurveContainer from "../CurveContainer/CurveContainer"
import { TextTypography } from "../SharedResources/Typography/TextTypography/TextTypography"
import {
  FontStyle,
  TextColor,
} from "../SharedResources/Typography/typography.service"

import "./About.scss"
const About: React.FC = () => {
  return (
    <section>
      <article className="mt-16">
        <CurveContainer>
          <div className="flex flex-col container mx-auto">
            <TextTypography
              className="font-semibold text-3xl sm:text-5xl"
              color={TextColor.WHITE}
            >
              About
            </TextTypography>
            <TextTypography
              className="mt-8 text-xl sm:text-3xl"
              fontStyle={FontStyle.ITALIC}
              color={TextColor.WHITE}
            >
              Dome is the only safe harbor for policy discovery
            </TextTypography>
            <TextTypography
              className="text-xl sm:text-3xl"
              fontStyle={FontStyle.ITALIC}
              color={TextColor.WHITE}
            >
              and debate
            </TextTypography>
          </div>
        </CurveContainer>
      </article>
      <article className="flex flex-col md:flex-row container mx-auto mt-16 md:space-x-16">
        <div className="md:h-96">
          <TextTypography className="text-3xl sm:text-5xl heading h-full">
            We are strictly nonpartisan and strictly neutral on each policy
            issue
          </TextTypography>
        </div>
        <div className="mt-16 md:mt-0 md:h-96 flex flex-col space-y-4">
          <TextTypography className="text-base">
            Our mission is to help the professionals who work in public policy
            gain skills, acquire knowledge, discover resources, and build
            relationships that will improve their daily work and which can
            propel great careers.
          </TextTypography>
          <TextTypography className="text-base">
            Dome is an opt-in platform. Civility is required.
          </TextTypography>
          <TextTypography className="text-base">
            Bots, conspiracy-circulators, fear-mongers, insult-spewers,
            mischief-makers, mud-throwers, spies, and trolls are banned.
          </TextTypography>
          <TextTypography className="text-base">
            Dome is a place for policy. We do not allow political campaigns — so
            you’ll never see “Vote for Blank” or “Vote against Blank” on Dome.
            There are many places for politics, just not ours.
          </TextTypography>
          <TextTypography className="text-base">
            The Dome platform is dedicated to the unique needs our audience.
          </TextTypography>
        </div>
      </article>
      <article className="mt-24">
        <CurveContainer>
          <div className="flex flex-col container mx-auto">
            <TextTypography
              className="font-semibold text-3xl sm:text-5xl"
              color={TextColor.WHITE}
            >
              Get the Tools You Need
            </TextTypography>
            <TextTypography
              className="mt-8 text-xl sm:text-3xl"
              fontStyle={FontStyle.ITALIC}
              color={TextColor.WHITE}
            >
              The Congressional Management Foundation reports that senior staff
              believe only 15 percent of all staffers have adequate knowledge,
              skills, and abilities to perform their jobs effectively.
            </TextTypography>
          </div>
        </CurveContainer>
      </article>
      <article className="flex flex-col md:flex-row container mx-auto mt-16 md:space-x-16">
        <div className="md:h-60">
          <TextTypography className="text-3xl sm:text-4xl heading h-full">
            We list every policy event we can find
          </TextTypography>
        </div>
        <div className="mt-16 md:mt-0 md:h-60 flex flex-col space-y-4 items-center">
          <TextTypography className="text-base">
            At our Dome Exclusive events, you can choose to ask questions
            anonymously.
          </TextTypography>
          <TextTypography className="text-base">
            Our network collapses the barriers of age, gender, ideology, party,
            and race.
          </TextTypography>
          <div className="pb-10">
            <Link to={"/apply-now/#plans"}>
              <Button className="bg-white py-3 px-16 rounded-lg mt-8 h-auto border border-primary">
                <TextTypography
                  className="text-base h-full w-full"
                  fontStyle={FontStyle.BOLD}
                  color={TextColor.PRIMARY}
                >
                  Get Started
                </TextTypography>
              </Button>
            </Link>
          </div>
        </div>
      </article>
      <article className="bg-no-repeat bg-cover bg-container p-5 pb-72 mt-16 ">
        <div className="flex flex-col container items-center mx-auto mt-16">
          <TextTypography
            className="text-3xl md:text-5xl text-center"
            fontStyle={FontStyle.ITALIC}
          >
            Dome is launching in stages. At first, we will be an easy-to-use
            calendar of free policy events in Washington and available online
          </TextTypography>
          <div className="pb-24">
            <Link to={"/apply-now/#plans"}>
              <Button className="bg-white py-3 px-16 rounded-lg mt-14 h-auto border border-primary">
                <TextTypography
                  className="text-base h-full w-full"
                  fontStyle={FontStyle.BOLD}
                  color={TextColor.PRIMARY}
                >
                  Get Started
                </TextTypography>
              </Button>
            </Link>
          </div>
        </div>
      </article>
    </section>
  )
}
export default About
